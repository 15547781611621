import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

window.api_url = window.location.origin;
//window.api_url = 'http://192.168.1.157:5959' //comment this line out for prod

// this file is the entry point for js/react in the entire project
// this function just shows index, a single page; however at the bottom root.render(<App />) is the
//   main part of react with the router

const Index = () => {
  return (
    <>
      <h1>Index Stuff Here</h1>
      <h3>news n victory n deals</h3>
    </>
  );
};

export default Index;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
