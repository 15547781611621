import { Outlet, Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';

// This file's PrimaryLayout is particularly referenced in App.js ex <Route path="/" element={<PrimaryLayout />}>
// It is used to wrap most the webpages in this React site
// It contains:
//   Nav/Header      this makes up a majority of the code with the search bar
//   <Outlet />      this is the variable content, sort of the meat this template surrounds
//   Footer

const PrimaryLayout = (
  {
    searchBarContents,
    setSearchBarContents,
    setSearchPageRefreshState,
  }) => {
  //navigate("path") to redirect between pages in functions like button onClick
  const navigate = useNavigate();

  // Search bar stuff here
  // Search bar contents state (user input text that's currently in the search bar) is in app.js
  //   as other components (namely search.js to display search results) need to access
  const [names, setNames] = useState([]);
  const [filteredNames, setFilteredNames] = useState([]);

  useEffect(
    () => {
      // fetch request autocomplete for autocomplete
      // These first two lines are to ensure we always only work with the most recent fetch requests ie
      //   the latest request is much faster than two requests ago
      const abortController = new AbortController();
      const signal = abortController.signal;

      const fetchAutoComplete = async () => {
        try {
          //console.log(searchBarContents)
          const request_init = {
            method: "POST",
            cache: "default",
            body: searchBarContents,
            signal
          };
          const response = await fetch(window.api_url + "/api/search/autocomplete", request_init)
          const data = await response.json();
          //console.log("suggest", data)
          if (response.ok && !signal.aborted) {
            setNames(data);
          }
        } catch (error) {
          if (error instanceof DOMException && error.name === 'AbortError') {
            return //this stops reporting of the error that occurs when past fetch requests are received later than more recently sent requests for /api/search/autocomplete
          }
          console.error("error fecthing search bar data: ", error)
        }
      };
    fetchAutoComplete()

    //cleanup to cancel previous fetch requests
    return () => abortController.abort();
    },
    [searchBarContents]
  );

  useEffect(() => {
      // Update the filteredNames whenever names or searchBarContents changes
      //  This is separate from fetching new predictions
      //  Rather, this just quickly shows subsets of what was previously known
      //    to look appealing while the fetch request for new predictions happens in the background more slowly
      // So if the user types 'chrom' there are lots of chrome and chromatic cards. So by typing the next character
      //   like 'chrome' or 'chroma' the frontend can quickly show the frontend changes while the fetch call may take a second
      // So if the fetch call takes a second and our search term has hundreds of results (its limited to ~10), this frontend
      //   will limit it even more to match our string, however since we see 10s of hundreds after the next fetch call finishes
      //   more hits will be added to the list. Example 'chrom' showed all 'chrome' cards by chance. But we update search to
      //   'chroma' and our list will temporarily look empty until the fetch request for updated predictions finishes
      const filtered = names.filter(name =>
        name.replace(/[,']/g, '').toLowerCase()
          .includes(searchBarContents.replace(/[,']/g, '').toLowerCase())
      );
      setFilteredNames(filtered);
    }, [searchBarContents, names]
  );


  const genericToSearchPage = () => {
    // No user input immediately calls this but rather is cleaned in the other functions
    // This state switch on the next line ensures that the search page is re-rendered even when navigating to it from itself
    setSearchPageRefreshState(prevState => !prevState) 
    navigate("/search")
  }

  const suggestionToSearchPage = (cardName) => {
    // This function is called upon clicking a suggestion/autocomplete in the search bar
    setSearchBarContents(cardName);
    genericToSearchPage()
  }

  const searchBarToSearchPage = (keyDownEvent) => {
    // this function is for pressing enter while in the search bar
    if (keyDownEvent.key === 'Enter')
      genericToSearchPage()
  }

  return (
    <>
      <nav>
          <input
            type="text"
            placeholder="Card Name"
            value={searchBarContents}
            onChange={e => setSearchBarContents(e.target.value)}   //this triggers the effects to filter and fetch card names
            onKeyDown={searchBarToSearchPage}               //searchBarToSearchPage makes it so when enter is pressed to go to the search results page
          />

        <ul>
          {filteredNames.map((name, index) => (
            <li key={index}>
              <button onClick={() => suggestionToSearchPage(name)} >
                {name}
              </button>
            </li>
          ))}
        </ul>
      </nav>

      <h1><Link
        to="/"
        style={{color:"blue", textDecoration:"none"}}
      >
          LB City Games
      </Link></h1>

      <div style={{border: '4px solid blue'}}>
        <Outlet />
      </div>

      <footer>
        <p style={{whiteSpace: 'nowrap'}}>
          Contact Damian lbcitygames
        </p>
      </footer>
    </>
  )
};

export default PrimaryLayout;
