import './App.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState } from 'react';

import PrimaryLayout from "./primaryLayout";
import Index from "./index";
import Printings from "./printing";
import Search from "./search";
import NoPage from "./noPage";

export default function App() {

  // This search bar contents state is mostly controlled by search bar in PrimaryLayout however
  //   other modules like search may need it so it's defined here to be passed in
  const [searchBarContents, setSearchBarContents] = useState('');
  const [searchPageRefreshState, setSearchPageRefreshState] = useState(false)

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={ <PrimaryLayout
                                      searchBarContents={searchBarContents}
                                      setSearchBarContents={setSearchBarContents}
                                      setSearchPageRefreshState={setSearchPageRefreshState}
                                  />}>
          <Route index element={<Index />} />
          <Route path="/printing/:id" element={<Printings />} />
          <Route path="/search" element={<Search rerenderAlert={searchPageRefreshState} searchBarContents={searchBarContents} />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
