import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react';


const Printings = () => {
  const { id } = useParams();
  const [printing, setPrinting] = useState(
    {
      name: "name",
      rarity: "rarity",
      uuid: "uuid",
      set: "set",
      finishes: ["finish1", "finish2"],
      language: "language",
    }
  );
  const [printingError, setPrintingError] = useState(false);
  
  const [images, setImages] = useState(['']);
  const [imagesError, setImagesError] = useState(false);

  const [inventory, setInventory] = useState([]) //list of inventory rows
  const [inventoryError, setInventoryError] = useState(false);
  //console.log(window.location.origin)

  useEffect(
    () => {
      // begin getting card details
      const fetchCard = async () => {
        try{
          //console.log("getting card ", card_url);

          //this first chunk gets the printing's details
          const card_url = window.api_url + "/api/printing/get/" + id;
          const card_response = await fetch(card_url);
          if (!card_response.ok) {
            setPrintingError(true);
            return
          }
          //setPrinting(await card_response.json()); // the await.then structure below does this line however i wanted console.log
          await card_response.json().then(
            printingData => {
              setPrinting(printingData)
              //console.log(printingData)
            }
          )


          //this next chunk gets the printing's inventory
          const inventory_url = window.api_url + "/api/printing/inventory/" + id;
          const inventory_response = await fetch(inventory_url);
          if (!inventory_response.ok) {
            setInventoryError(true);
            return
          }
          //setPrinting(await card_response.json()); // the await.then structure below does this line however i wanted console.log
          await inventory_response.json().then(
            inventoryData => {
              setInventory(inventoryData)
              //console.log(inventoryData)
            }
          )


          //this next chunk gets the printing's picture(s)
          const images_url = window.api_url + "/api/printing/pictures/" + id;
          const images_response = await fetch(images_url);
          if (!images_response.ok) {
            setImagesError(true);
            return
          }
          //setImages(await images_response.json()); // the await.then structure below does this line however i wanted console.log
          await images_response.json().then(
            images => {
              setImages(images)
              //console.log(images)
            }
          )

        } catch (exception) {
          console.log("exception ", exception)
        }
      }

      fetchCard();
    },
    [id]
  )



  // return
  // fail
  if (printingError) {
    return (
      <>
        <h1>printingError 6100</h1>
        <h2>If this card exists please report this</h2>
      </>
    );
  }

  // success
  const finish_list = printing.finishes.map((finish, index) => (
    <li key={index}>{finish}</li>
  ));
  let inventory_list = <h3>Out of stock!</h3>;
  if(inventory.length > 0) {
    inventory_list =
      <table style={{border: "1px solid black"}}>
        <thead>
          <tr>
            <th>Finish</th>
            <th>Condition</th>
            <th>Stock</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {inventory.map((row, index)=>(
            <tr key={index}>
              <td>{row.finish}</td>
              <td>{row.condition_abbreviation}</td>
              <td>{row.quantity}</td>
              <td>9999999</td>{/*TODO fix this to use row.price or whatever when i fix api.printing_inventory.rs*/}
            </tr>
          ))}
        </tbody>
      </table>
  }
  if(inventoryError) {
    console.log("inventory error?", inventory_list)
    inventory_list = <>
      <h3>ERROR CODE 1510</h3>
      <h5>Please contact to report this issue or get support</h5>
    </>
  }

  const image_list = images.map((base64, index) => (
    <img
      key={index}
      src={"data:image/jpeg;base64,"+base64}
      alt={"Face " + (index+1)}
    />
  ));
  if(imagesError) {
    console.log("ERROR CODE 1530. IMAGE ERROR. image_list?:", image_list)
  }

  return (
    <>
      <h1>{printing.name}</h1>
      <h2>{printing.set}</h2>
      <h2>{printing.rarity}</h2>
      <h2>{printing.language}</h2>
      {finish_list}
      {image_list}
      {inventory_list}
    </>
  );
};

export default Printings;
