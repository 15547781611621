import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

const Search = ({rerenderAlert, searchBarContents}) => {
  // Only fetch all results when this page loads, not based on other states like search bar
  //   This is so typing in the search bar doesn't overwhelm the server

  //Defining starting states
  const temp = Array.from({length: 3}, (_, index) => ({
    name:"awaiting",
    uuid:"AWAITING",
    set:"",
    rarity:"",
    language:"",
    finishes:[],
    stock:0,
  }));
  const [validResults, setValidResults] = useState(false) // true when search results has real data. doesn't care about thumbnails
  const [searchResults, setSearchResults] = useState(temp);
  const [thumbnails, setThumbnails] = useState([])

  //Just defining the driving function, to later be called especially on page load effect
  const fetchSearchResults = async () => {
    try {
      //console.log(searchBarContents)
      const response = await fetch(
        window.api_url + "/api/search/browse",
        {
          method: "POST",
          cache: "default",
          body: JSON.stringify({query:searchBarContents, set:""})
        }
      )
      
      if (!response.ok) {
        return
      }
      const data = await response.json();
      //console.log("search/browse", data)
      setSearchResults(data);
      setValidResults(true)
      // Make correct length state for thumbnails state
      // The for loop also styles the printing.stock
      let thumbnailInit = []
      for(let i=0; i<data.length; i++) {
        let printing = data[i]
        printing.index = i
        thumbnailInit.push('')
        if (printing.stock < 1) {
          printing.stock = "None in stock"
        } else {
          printing.stock = printing.stock + " in stock"
        }
      }
      setThumbnails(thumbnailInit)
    } catch (error) {
      console.error("error fecthing search results: ", error)
    }
  }

  useEffect(
    () => {
      // This effect only runs on page load/rerender like going to a different search query
      //console.log("re-rendering")
      fetchSearchResults()
      //console.log("search results", searchResults)
    },
    [rerenderAlert] // Empty dependencies parameter means to only run this effect once on page load
  );

  
  // Define driver function for next effect to load images
  const getThumbnail = async (searchResult) => {
    const index = searchResult.index
    let base64 = 'iVBORw0KGgoAAAANSUhEUgAAAAgAAAAIAQMAAAD+wSzIAAAABlBMVEX///+/v7+jQ3Y5AAAADklEQVQI12P4AIX8EAgALgAD/aNpbtEAAAAASUVORK5CYII'

    const images_url = window.api_url + "/api/printing/pictures/" + searchResult.uuid;
    const images_response = await fetch(images_url);
    if (!images_response.ok) {
      console.error("thumbnail error");
      return
    }
    //setImages(await images_response.json()); // the await.then structure below does this line however i wanted console.log
    await images_response.json().then(
      images => {
        base64 = images[0]
        //console.log(base64)
      }
    )

    setThumbnails(oldThumbnails => {
      const newThumbnails = [...oldThumbnails];
      newThumbnails[index] = base64;
      return newThumbnails;
    });
  }

  useEffect(
    // This function lazily updates the images from placeholders
    // See how this effect is dependent on search results
    () => {
      if(!validResults) {
        // This if statement ensure to not try to get images for the default/temp/placeholder state of searchResults
        // Thats the 3 blank cards 'awaiting'
        return
      }
      //console.log("search results changes", searchResults)
      searchResults.forEach(searchResult => getThumbnail(searchResult))
    },
    [searchResults]
  )

  return (
    <>
      {/*
      DO HEADER STUFF HERE
      like maybe TODO a filter/toolbar
      <h1>Search Stuff Here</h1>
      <h2>search results n stuff</h2>
      example im doing the card count here
      */}
      <h2>{validResults ? searchResults.length : '?'} Results</h2>
      
      <div className="search-results" style={{display:'flex', flexWrap:'wrap', border:'2.5px orange solid'}}>
        {searchResults.map((item, index) => (
          <Link 
            key={index}
            to={validResults ? "/printing/" + item.uuid : "/search"} //if we dont have real results 3 then don't put real links
            style={
              {
                textDecoration:'none',
                color:'black',
              }
            }
          >
            <div style={{border:'2.5px black solid', margin:'.5vh 1vw', width: '17vw'}}>
              <h2>{item.name}</h2>
              <h4>{item.set}</h4>
              <div style={{textAlign:'center'}}>
                {
                  <img
                    src={thumbnails[item.index] ? `data:image/jpeg;base64,${thumbnails[item.index]}` : "/unavailable_card.jpg"}
                    style={{ width: '90%' }}
                    alt={item.name + " (from set) " + item.set}
                  />
                }
              </div>

              <h2>{item.stock}</h2>
              <h5>{item.rarity}</h5>
              {item.finishes.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
              <h5>{item.language}</h5>
            </div>
          </Link>
        ))}
      </div>


    </>
  );
};

export default Search;
